import $ from 'jquery';

window.jQuery = window.$ = $;

import CustomSelect from "./CustomSelect";
import Navigation from "./Navigation";
import HashNavigation from "./HashNavigation";

class Root {

    constructor() {
        this.navigation = new Navigation;
        this.hashNavigation = new HashNavigation;
        this.customSelect = new CustomSelect;
    }

    revealRating() {
        let self = this;
        let ratings = document.querySelectorAll('.progress-bar');

        let events = ['DOMContentLoaded', 'JsPageReload', 'scroll'];

        events.forEach(function (event) {
            document.addEventListener(event, function () {
                self.setAnimation(ratings);
            });
        });
    }

    isInViewport(el) {
        let rect = el.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }

    setAnimation(ratings){
        let self = this;
        ratings.forEach(function (rating) {
            if(self.isInViewport(rating)){
                let inner = rating.querySelector('.progress-inner');
                inner.style.width = inner.dataset.width + '%';
            }
        })
    }

    run() {
        this.revealRating();
        this.navigation.run();
        document.dispatchEvent(new Event("JsPageReload"));
    }

}

let App = new Root();
window.App = App;
