export default class Navigation {

    constructor() {

    }

    smoothNavigation() {
        $('#page').smoothState({
            prefetch: true,
            cacheLength: 3,
            blacklist: 'form'
        })
    };

    mobileMenu() {
        $('.mobile-btn').click(function () {
            $(this).toggleClass('active');
            $(this).closest('.header').find('.js-mobile-menu').toggleClass('hidden');
            $(this).closest('.header').find('.mobile-menu-wrap').toggleClass('active-menu');
        })
    }


    toTop() {
        $(window).scroll(function () {
            if ($(this).scrollTop() != 0) {
                $('#toTop').addClass('show');
            } else {
                $('#toTop').removeClass('show');
            }
        });
        $('#toTop').click(function () {
            $('body,html').animate({scrollTop: 0}, 500);
        });
    };


    run() {
        this.smoothNavigation();
        this.mobileMenu();
        this.toTop();
    }

}