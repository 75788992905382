export default class HashNavigation {
    constructor() {

    }

    run() {
        let links = document.querySelectorAll('.hash-link');
        links.forEach(link => {
            link.addEventListener('click', function (e) {
                e.preventDefault();
                let href = this.hash;
                let target = document.querySelector(href);
                window.scrollTo({
                    top: target.offsetTop,
                    behavior: "smooth"
                });
            })
        })
    }

    anchorToblock() {
        let hash_window = window.location.hash;
        let offeset_top = 0;
        if (window.innerWidth <= 1024 && hash_window) {
            hash_window += '-mobile';
            offeset_top -= 50;
        } else {
            hash_window = window.location.hash;
        }
        if (hash_window) {
            $('html, body').animate({
                scrollTop: $(hash_window).offset().top + offeset_top
            }, 500, function () {
                console.log('after');
            });
            return;
        }
    }

    setActiveOption() {
        let hash_window = window.location.hash;
        if (hash_window) {
            let opener = document.querySelector('.custom-select-opener');
            let hidden = document.querySelector('#subject');
            $('.custom-select-option').each(function (index, elem) {
                if (elem.dataset.value === 'Listing Request') {
                    elem.classList.add('selected');
                    opener.innerText = $(elem).text();
                    hidden.value = elem.dataset.value;
                    return false;
                }
            })
        }

    }
}