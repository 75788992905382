export default class CustomSelect {

    constructor() {

    }

    run() {

        let container = document.querySelector('.custom-select-container');
        let opener = document.querySelector('.custom-select-opener');
        let options = document.querySelectorAll('.custom-select-option');
        let hidden = document.querySelector('#subject');
        opener.addEventListener('click', function () {
            if (container.classList.contains('is-open')) {
                container.classList.remove('is-open');
                return
            }
            container.classList.add('is-open');
        });

        options.forEach(function (option) {
            option.addEventListener('click', function () {
                $('.custom-select-opener').removeClass('error-input');
                options.forEach(function (inactiveOption) {
                    inactiveOption.classList.remove('selected');

                });
                this.classList.add('selected');
                opener.innerText = this.innerText;
                container.classList.remove('is-open');
                hidden.value = this.dataset.value
            })
        })
    }

    formSubmit() {
        $('#contact_form').on('submit', function (e) {
            $(".required").removeClass('error-input');
            $(".required").filter(function () {
                return !this.value;
            }).addClass("error-input");
            if ($('.hidden').hasClass('error-input')) {
                $('.custom-select-opener').addClass('error-input')
            } else {
                $('.custom-select-opener').removeClass('error-input')
            }
            if ($('.required').hasClass('error-input')) {
                e.preventDefault();
                $('.error-input').each(function (index, elem) {
                    console.log($(elem).closest('label').find('.input-title'));
                    $(elem).closest('label').find('.input-title').addClass('hidden');
                    $(elem).closest('label').find('.input-title-error').addClass('visible');
                });
            }
        })
    }

    checkLengthSymbol() {
        const limit = 1000;
        let delta = 0;
        $('#message').on('input', function () {
            let current_length = $(this).val().length;
            delta = limit - current_length;
            $("#count").html(delta);
        });
    }
}
